<template>
	<div class="activities-reports">
       <div class="container-fluid">
        <mdb-row>
            <mdb-col sm="12" md="12" lg="10" class="mx-auto">
                <div class="card card-reversed color-normal-reversed">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h4>Rapport d'activité</h4>
                            <div>
                                <mdb-btn @click="exportPdfActivities()" icon="download" tag="a" size="sm"  color="primary" class="m-0"></mdb-btn>
                                <mdb-dropdown btn-group split dropleft>
                                <mdb-dropdown-toggle slot="toggle" color="primary" size="sm" class="px-2 py-2"></mdb-dropdown-toggle>
                                <mdb-dropdown-menu>
                                    <mdb-dropdown-item ><i class="fas fa-download"></i> Exporter en pdf</mdb-dropdown-item>
                                </mdb-dropdown-menu>
                                </mdb-dropdown>
                            </div>
                        </div>
                        <form @submit.prevent="loadActivities">
                            <mdb-row >
                                <mdb-col sm="12" md="6" lg="4" class="mb-n4">
                                    <mdb-date-picker-2 
                                            :options="option"
                                            :validation="$store.state.report.errors.start ? true :false" :invalidFeedback="$store.state.report.errors.start"
                                            v-model="form.start_date"
                                            v-mask="'####-##-##'"  autoHide outline  label="Date Début" title="Date Début" />
                                </mdb-col>
                                <mdb-col sm="12" md="6" lg="4" class="mb-n4">
                                    <mdb-date-picker-2 
                                            :options="option"
                                            :validation="$store.state.report.errors.end ? true :false" :invalidFeedback="$store.state.report.errors.end"
                                            v-model="form.end_date"
                                            v-mask="'####-##-##'"  autoHide outline  label="Date Fin" title="Date Fin" />
                                </mdb-col>
                                <mdb-col sm="12" md="6" lg="4" class=""  v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                                    <mdb-select flipOnScroll 
                                    wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed "
                                    class="mb-n3" @getValue="getSite" multiple selectAll  selectAllPlaceholder="Tout selectionner" v-model="siteOption"
                                     outline label="Sites"></mdb-select>
                                    <div  style="color:#dc3545;font-size:.8rem" v-if="$store.state.report.errors.sites" >{{$store.state.report.errors.sites}}</div>

                                </mdb-col>
                                 <mdb-col sm="12" md="6" lg="4" class=""  v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                                    <mdb-select flipOnScroll 
                                    wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed "
                                    class="mb-n3"  :validation="errors.users ? true : false" :invalidFeedback="errors.users" @getValue="getUser" multiple selectAll  selectAllPlaceholder="Tout selectionner" 
                                    v-model="userOption" outline label="Utilisateurs"></mdb-select>
                                    <div  style="color:#dc3545;font-size:.8rem" v-if="$store.state.report.errors.users" >{{$store.state.report.errors.users}}</div>

                                    
                                </mdb-col>
                                <mdb-col sm="12" class="d-flex justify-content-end mt-2">
                                    <button type="submit" class="btn btn-primary btn-md">Rechercher</button>
                                </mdb-col>
                            </mdb-row>
                        </form>

                        <div class="content" v-loading="loading">
                            <mdb-tbl>
                                <mdb-tbl-head >
                                    <tr  class="color-normal-reversed">
                                        <th></th>
                                        <th style="text-align:right;width:200px" >Vente</th>
                                        <th style="text-align:right;width:200px" >Avoir</th>
                                        <th style="text-align:right;width:200px" >Total</th>
                                    </tr>
                                </mdb-tbl-head>
                                <mdb-tbl-body class="color-normal-reversed">
                                    <tr class="content_list">
                                        <td >Facture de vente HT</td>
                                        <td >{{parseFloat(data.ht.fv) - parseFloat(data.rcsSts.fv) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ht.fa) - parseFloat(data.rcsSts.fa) | numberRound | formatNumber}}</td>
                                        <td >{{calculTotalFactureVente| numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Facture d'export HT</td>
                                        <td >{{parseFloat(data.ht.ev) - parseFloat(data.rcsSts.ev) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ht.ea) - parseFloat(data.rcsSts.ev) | numberRound | formatNumber}}</td>
                                        <td >{{(parseFloat(data.ht.ev) - parseFloat(data.rcsSts.ev)) -
                                              (parseFloat(data.ht.ea) - parseFloat(data.rcsSts.ev)) | numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total Facture HT</td>
                                        <td >{{
                                            (parseFloat(data.ht.fv) - parseFloat(data.rcsSts.fv)) + 
                                            (parseFloat(data.ht.ev) - parseFloat(data.rcsSts.ev)) | numberRound | formatNumber}}</td>
                                        <td >{{
                                            (parseFloat(data.ht.fa) - parseFloat(data.rcsSts.fa)) + 
                                            (parseFloat(data.ht.ea) - parseFloat(data.rcsSts.ea)) | numberRound | formatNumber}}</td>
                                        <td >{{
                                            ((parseFloat(data.ht.fv) - parseFloat(data.rcsSts.fv)) + 
                                            (parseFloat(data.ht.ev) - parseFloat(data.rcsSts.ev)))
                                            -((parseFloat(data.ht.fa) - parseFloat(data.rcsSts.fa)) +
                                             (parseFloat(data.ht.ea) - parseFloat(data.rcsSts.ea)))
                                            | numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total Taxe Spécifique</td>
                                        <td >{{parseFloat(data.ts.fvev)| numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ts.faea) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ts.fvev) - parseFloat(data.ts.faea) | numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total TVA</td>
                                        <td >{{parseFloat(data.tva.fvev)| numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.tva.faea) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.tva.fvev) - parseFloat(data.tva.faea) | numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total Autre Tax</td>
                                        <td >{{parseFloat(data.rcsSts.fv) + parseFloat(data.rcsSts.ev) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.rcsSts.fa) + parseFloat(data.rcsSts.ea) | numberRound | formatNumber}}</td>
                                        <td >{{
                                            (parseFloat(data.rcsSts.fv) + parseFloat(data.rcsSts.ev)) - 
                                            (parseFloat(data.rcsSts.fa) + parseFloat(data.rcsSts.ea)) 
                                            | numberRound | formatNumber
                                        }}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total AIB Vente en gros</td>
                                        <td >{{parseFloat(data.aib.fvev)| numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.aib.faea) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.aib.fvev) - parseFloat(data.aib.faea) | numberRound | formatNumber}}</td>
                                    </tr>
                                    <tr class="content_list color-normal-reversed">
                                        <td >Total Facture TTC</td>
                                        <td >{{parseFloat(data.ttc.fvev)| numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ttc.faea) | numberRound | formatNumber}}</td>
                                        <td >{{parseFloat(data.ttc.fvev) - parseFloat(data.ttc.faea) | numberRound | formatNumber}}</td>
                                    </tr>
                                </mdb-tbl-body>
                            </mdb-tbl>
                        </div>

                        <div class="pt-5" v-loading="loading" >
                            <div> Nombre d'agent factureur: {{data.nbrUserFacturer}} </div>
                            <div> Nombre de site de facturation: {{data.nbrSiteFacturer}} </div>
                            <div> Nombre de client facturé: {{data.nbrCustomerFacturer}} </div>
                            <div> Nombre d'article vendu: {{data.nbrArticleFacturer}} </div>
                        </div>
                    </div>
                </div>
            </mdb-col>
        </mdb-row>
       </div>
	</div>
</template>

<script>
import download from "../../../services/download-fetch"

import {
	mdbRow,
	mdbCol,
	mdbSelect,
	mdbDatePicker2,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbBtn,
    mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle,
} from "mdbvue";
export default {
name:'activities-reports',
components: {
    mdbRow,
	mdbCol,
	mdbSelect,
	mdbDatePicker2,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbBtn,
    mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle,
},

data(){
    const now = new Date()
    const first = now.getFullYear()+'-'+"01"+'-'+"01"; 
    const last = now.getFullYear()+'-'+"12"+'-'+"31";
	return {
		option: {
            week: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            month: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            placeholder: 'choisir une date',
            buttons: {
                ok: 'Ok',
                clear: 'Effacer',
                cancel: 'annuler',
                today: 'Aujourd hui'
            }
        },
        sites:[],
        siteOption:[],
        users:[],
        userOption:[],

        
        loading:false,
        form:{
            start_date:first,
            end_date:last,
            sites:[],
            users:[],
        },
         errors:{
            sites:"",
            users:"",
            start:"",
            end:""
        },
        data:{
           
            ht:{
                fv:0,
                ev:0,
                ea:0,
                fa:0,
            },
            aib:{
                fvev:0,
                faea:0
            },
            ts:{
                fvev:0,
                faea:0
            },
            rcsSts:{
                fv:0,
                fa:0,
                ev:0,
                ea:0,
            },
            ttc:{
                fvev:0,
                faea:0
            },
            tva:{
                fvev:0,
                faea:0
            },
            nbrUserFacturer:0,
            nbrSiteFacturer:0,
            nbrCustomerFacturer:0,
            nbrArticleFacturer:0,
            
        },

        dataExport:{
            v_ht:{
                vente:0,
                avoir:0,
                total:0,
            },
            e_ht:{
                vente:0,
                avoir:0,
                total:0,
            },
            tf_ht:{
                vente:0,
                avoir:0,
                total:0,
            },
            aib:{
                vente:0,
                avoir:0,
                total:0,
            },
            ts:{
                vente:0,
                avoir:0,
                total:0,
            },
            other_tax:{
                vente:0,
                avoir:0,
                total:0,
            },
            ttc:{
                vente:0,
                avoir:0,
                total:0,
            },
            tva:{
                vente:0,
                avoir:0,
                total:0,
            },
            nbrUserFacturer:0,
            nbrSiteFacturer:0,
            nbrCustomerFacturer:0,
            nbrArticleFacturer:0,
            print_by :"",
            company :this.$store.state.auth.company.name,
            print_date :"",
            print_period :"",
            siteChoosed:[],
            userChoosed:[],
        }
	}
},

methods:{
	async loadSites () {
        this.$nprogress.start()
        await this.$store.dispatch('site/findAll')
        .then(response => {
            this.$nprogress.done()
            this.sites = response.data.sites

            this.sites.forEach(site => {
                this.siteOption.push({
                    text:site.name,
                    value:site.id
                })
            });
        }).catch((error) => {
            this.$nprogress.done()
            this.$notify({
                   
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        })
    },

    async loadUsers () {
        this.$nprogress.start()
        await this.$store.dispatch('user/findAll')
        .then(response => {
            this.$nprogress.done()
            this.users = response.data.users

            if(this.$gate.checkTypeRole(['super-admin','admin'])){
                this.userOption.push({
                    text:this.$store.state.auth.user.firstname+' '+this.$store.state.auth.user.lastname,
                    value:this.$store.state.auth.user.id
                })
            }
            

            this.users.forEach(user => {
                this.userOption.push({
                    text:user.firstname+' '+user.lastname,
                    value:user.id
                })
            });
        }).catch((error) => {
            this.$nprogress.done()
            this.$notify({
                   
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        })
    },

    getSite(value){
        this.form.sites = value
    },

    getUser(value){
        this.form.users = value
    },

    reset(){
            this.data.ht.fv = 0
            this.data.ht.fa = 0
            this.data.ht.ev = 0
            this.data.ht.ea = 0

            this.data.ts.fvev = 0
            this.data.ts.faea = 0

            this.data.tva.fvev = 0
            this.data.tva.faea =0

            this.data.aib.fvev = 0
            this.data.aib.faea = 0

            this.data.ttc.fvev = 0
            this.data.ttc.faea =0

            this.data.rcsSts.fv = 0
            this.data.rcsSts.ev = 0
            this.data.rcsSts.fa = 0
            this.data.rcsSts.ea = 0

            this.data.nbrUserFacturer =0
            this.data.nbrSiteFacturer = 0
            this.data.nbrCustomerFacturer = 0
            this.data.nbrArticleFacturer = 0

            this.calculDataExport()

    },

    loadActivities(){
        this.$nprogress.start()
        this.loading = !this.loading
        this.$store.commit('report/SET_CLEAN')
        this.$store.dispatch('report/activitiesReports', this.form).then((response)=>{
            this.$nprogress.done()
            this.loading = !this.loading

            this.data.ht.fv = response.data.activities.ht.FV.vente
            this.data.ht.fa = response.data.activities.ht.FA.vente
            this.data.ht.ev = response.data.activities.ht.EV.vente
            this.data.ht.ea = response.data.activities.ht.EA.vente

            this.data.ts.fvev = response.data.activities.ts.FVEV.vente
            this.data.ts.faea = response.data.activities.ts.FAEA.vente

            this.data.tva.fvev = response.data.activities.tva.FVEV.vente
            this.data.tva.faea = response.data.activities.tva.FAEA.vente

            this.data.aib.fvev = response.data.activities.aib.FVEV.vente
            this.data.aib.faea = response.data.activities.aib.FAEA.vente

            this.data.ttc.fvev = response.data.activities.ttc.FVEV.vente
            this.data.ttc.faea = response.data.activities.ttc.FAEA.vente

            this.data.rcsSts.fv = response.data.activities.rcsSts.FV.vente
            this.data.rcsSts.ev = response.data.activities.rcsSts.EV.vente
            this.data.rcsSts.fa = response.data.activities.rcsSts.FA.vente
            this.data.rcsSts.ea = response.data.activities.rcsSts.EA.vente

            this.data.nbrUserFacturer = response.data.activities.nbrUserFacturer
            this.data.nbrSiteFacturer = response.data.activities.nbrSiteFacturer
            this.data.nbrCustomerFacturer = response.data.activities.nbrCustomerFacturer
            this.data.nbrArticleFacturer = response.data.activities.nbrArticleFacturer

            this.calculDataExport()
        }).catch((error)=>{
            this.$nprogress.done()
            this.loading = !this.loading
            this.reset()
            if (error.response.data.errors) {
                this.$store.commit('report/SET_START', error.response.data.errors.start_date)
                this.$store.commit('report/SET_END', error.response.data.errors.end_date)
                this.$store.commit('report/SET_SITES', error.response.data.errors.sites)
                this.$store.commit('report/SET_USERS', error.response.data.errors.users)
            }
            this.$notify({
                   
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        })
    },

    async exportPdfActivities(){
        await download({
            url:'/reports/activities/pdf',
            method:"POST",
            data:this.dataExport,
            isTh:0,
            filename:"rapport_activite"+'.pdf'
        }).then(() => {
            this.$notify({
                 
                message: "PDF Généré avec succès",
                type: 'success'
            })
        })
        .catch(() => {
            this.$notify({
                   
                message: "Echec durant l'exportation, reessayer ulterieurement",
                type: 'danger'
            })
        })
    },

    calculDataExport(){
        this.dataExport.v_ht.vente = parseFloat(this.data.ht.fv) - parseFloat(this.data.rcsSts.fv)
        this.dataExport.v_ht.avoir = parseFloat(this.data.ht.fa) - parseFloat(this.data.rcsSts.fa)
        this.dataExport.v_ht.total = (parseFloat(this.data.ht.fv) - parseFloat(this.data.rcsSts.fv)) - (parseFloat(this.data.ht.fa) - parseFloat(this.data.rcsSts.fa))

        this.dataExport.e_ht.vente = parseFloat(this.data.ht.ev) - parseFloat(this.data.rcsSts.ev)
        this.dataExport.e_ht.avoir = parseFloat(this.data.ht.ea) - parseFloat(this.data.rcsSts.ev)
        this.dataExport.e_ht.total = (parseFloat(this.data.ht.ev) - parseFloat(this.data.rcsSts.ev)) - (parseFloat(this.data.ht.ea) - parseFloat(this.data.rcsSts.ev)) 

        this.dataExport.tf_ht.vente = this.dataExport.v_ht.vente + this.dataExport.e_ht.vente
        this.dataExport.tf_ht.avoir = this.dataExport.v_ht.avoir + this.dataExport.e_ht.avoir
        this.dataExport.tf_ht.total = this.dataExport.v_ht.total + this.dataExport.e_ht.total

        this.dataExport.ts.vente =  parseFloat(this.data.ts.fvev)
        this.dataExport.ts.avoir =  parseFloat(this.data.ts.faea)
        this.dataExport.ts.total  = this.dataExport.ts.vente - this.dataExport.ts.avoir

        this.dataExport.tva.vente =  parseFloat(this.data.tva.fvev)
        this.dataExport.tva.avoir =  parseFloat(this.data.tva.faea)
        this.dataExport.tva.total  = this.dataExport.tva.vente - this.dataExport.tva.avoir

        this.dataExport.other_tax.vente =  parseFloat(this.data.rcsSts.fv) + parseFloat(this.data.rcsSts.ev)
        this.dataExport.other_tax.avoir =  parseFloat(this.data.rcsSts.fa) + parseFloat(this.data.rcsSts.ea)
        this.dataExport.other_tax.total  = this.dataExport.other_tax.vente - this.dataExport.other_tax.avoir

        this.dataExport.aib.vente =  parseFloat(this.data.aib.fvev)
        this.dataExport.aib.avoir =  parseFloat(this.data.aib.faea)
        this.dataExport.aib.total  = this.dataExport.aib.vente - this.dataExport.aib.avoir

        this.dataExport.ttc.vente =  parseFloat(this.data.ttc.fvev)
        this.dataExport.ttc.avoir =  parseFloat(this.data.ttc.faea)
        this.dataExport.ttc.total  = this.dataExport.ttc.vente - this.dataExport.ttc.avoir

        this.dataExport.nbrUserFacturer =  this.data.nbrUserFacturer
        this.dataExport.nbrSiteFacturer =  this.data.nbrSiteFacturer
        this.dataExport.nbrCustomerFacturer =  this.data.nbrCustomerFacturer
        this.dataExport.nbrArticleFacturer =  this.data.nbrArticleFacturer

        this.dataExport.print_by =  this.$store.state.auth.user.firstname+' '+this.$store.state.auth.user.lastname
        this.dataExport.print_date = this.$moment().format('YYYY-MM-DD'); 
        this.dataExport.print_period = this.form.start_date+' au '+this.form.end_date

        let sitelet = [];
        this.dataExport.siteChoosed = []

        this.siteOption.forEach(site => {
            this.form.sites.forEach(site1 => {
               if(site.value === site1){
                   sitelet.push(site.text)
               }
            })
        })
        this.dataExport.siteChoosed = sitelet

        let userlet = [];
        this.dataExport.userChoosed = []

        this.userOption.forEach(user => {
            this.form.users.forEach(user1 => {
               if(user.value === user1){
                   userlet.push(user.text)
               }
            })
        })
        this.dataExport.userChoosed = userlet

    }

},
created(){
    this.loadSites()
    this.loadUsers()
},

computed:{
    calculTotalFactureVente(){
        let fv = parseFloat(this.data.ht.fv) - parseFloat(this.data.rcsSts.fv)
        let fa = parseFloat(this.data.ht.fa) - parseFloat(this.data.rcsSts.fa)
        return fv - fa
    }
}
}
</script>
<style lang="scss">
    .content_list td:nth-child(2),
    .content_list td:nth-child(3),
    .content_list td:nth-child(4){
        text-align: right;
    }

     .content_list:nth-child(8) td,
     .content_list:nth-child(3) td{
         font-weight: 600;
         border-bottom: 1px solid #555;
         border-top: 1px solid #555 !important;
     }
</style>